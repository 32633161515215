import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
import figureFormatter from "@/services/utils/figureFormatter";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {

    const { formatDate } = useDate();
    const { commaFormat } = figureFormatter();
    const store = useStore();
    const userName = store.state.user.user.name;

    const exportToPDF = (company, reports, payload, stockType = 'quantity') => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 80 ],
            pageOrientation: 'landscape',
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(reports, payload, stockType),
            styles : {
                header: {
                    fontSize: 24
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: `Stock Register Detailed Report ${stockType === 'quantity' ? 'Quantity' : 'Value'}`
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (reports, payload, stockType) => {
        return [
            {
                text: `Stock Register Detailed Report ${stockType === 'quantity' ? 'Quantity' : 'Value'}`,
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },
            {
                stack: [
                    { text: `${payload.location ? 'Location: ' +  payload.location : ''}` },
                    { text: `${payload.supplier ? 'Supplier: ' +  payload.supplier : ''}` },
                    { text: `${payload.product ? 'Product: ' +  payload.product : ''}` },
                    { text: `${payload.start_date ? 'From: ' +  payload.start_date : ''}` },
                    { text: `${payload.end_date ? 'To: ' +  payload.end_date : ''}` },
                ].filter(Boolean)
            },
            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: getTableHeaderSize(stockType),
                    body: getTableBody(reports, stockType)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const getTableHeaderSize = (stockType) => {
        let width = [30,'*', 50, 50, 50, 50, 50, 50, 50, 50, 50];

        if(stockType === 'value') {
           width = width.concat([50, 50]); 
        }
        
        return width;
    }

    const getTableBody = (reports, stockType) => {
        const header = getTableHead(stockType);
        const rows = getTableRow(reports, stockType);
        
        rows.unshift(header);
        return rows;
    }

    const getTableRow = (reports, stockType) => {

        let totals = {
            opening_stock: 0,
            purchase: 0,
            purchase_return: 0,
            stock_transfer: 0,
            stock_adjustment: 0,
            consumption: 0,
            sales_invoice: 0,
            sales_return: 0,
            closing_stock: 0,
            total_sale_value: 0,
            total_profit_value: 0
        }

        const rows = reports.map((item,i) => {

            totals.opening_stock += item.opening_stock;
            totals.purchase += item.purchase;
            totals.purchase_return += item.purchase_return;
            totals.stock_transfer += item.stock_transfer;
            totals.stock_adjustment += item.stock_adjustment;
            totals.consumption += item.consumption;
            totals.sales_invoice += item.sales_invoice;
            totals.sales_return += item.sales_return;
            totals.closing_stock += item.closing_stock;

            let row = [
                { text: i+1, alignment: 'center' },
                { text: item.product_name },
                { text: commaFormat(item.opening_stock), alignment: 'right' },
                { text: commaFormat(item.purchase), alignment: 'right' },
                { text: commaFormat(item.purchase_return), alignment: 'right' },
                { text: commaFormat(item.stock_transfer), alignment: 'right' },
                { text: commaFormat(item.stock_adjustment), alignment: 'right' },
                { text: commaFormat(item.consumption), alignment: 'right' },
                { text: commaFormat(item.sales_invoice), alignment: 'right' },
                { text: commaFormat(item.sales_return), alignment: 'right' },
                { text: commaFormat(item.closing_stock), alignment: 'right' },
            ];

            if(stockType === 'value') {
                row = row.concat([
                    { text: commaFormat(item.total_sale_value), alignment: 'right' },
                    { text: commaFormat(item.total_profit_value), alignment: 'right' }
                ]);

                totals.total_sale_value += item.total_sale_value;
                totals.total_profit_value += item.total_profit_value;
            }

            return row;
        });

        let totalRow = [
            { text: '', alignment: 'right', bold: true },
            { text: `Total (${reports.length})`, alignment: 'right', bold: true },
            { text: commaFormat(totals.opening_stock), alignment: 'right', bold: true },
            { text: commaFormat(totals.purchase), alignment: 'right', bold: true },
            { text: commaFormat(totals.purchase_return), alignment: 'right', bold: true },
            { text: commaFormat(totals.stock_transfer), alignment: 'right', bold: true },
            { text: commaFormat(totals.stock_adjustment), alignment: 'right', bold: true },
            { text: commaFormat(totals.consumption), alignment: 'right', bold: true },
            { text: commaFormat(totals.sales_invoice), alignment: 'right', bold: true },
            { text: commaFormat(totals.sales_return), alignment: 'right', bold: true },
            { text: commaFormat(totals.closing_stock), alignment: 'right', bold: true },
        ];

        if(stockType === 'value') {
            totalRow = totalRow.concat([
                { text: commaFormat(totals.total_sale_value), alignment: 'right', bold: true },
                { text: commaFormat(totals.total_profit_value), alignment: 'right', bold: true }
            ]);
        }
    
        rows.push(totalRow);
        return rows;
    }

    const getTableHead = (stockType) => {
        let header = [
            { text: 'SL#', alignment: 'center' },
            { text: 'SERVICE NAME' },
            { text: 'Opening Stock', alignment: 'right'},
            { text: 'Purchase/ GRN / Production', alignment: 'right'},
            { text: 'PURCHASE RETURN', alignment: 'right'},
            { text: 'TRANSFER', alignment: 'right'},
            { text: 'STOCK ADJUSTMENT', alignment: 'right'},
            { text: 'CONSUMPTION', alignment: 'right'},
            { text: 'SALES INVOICE', alignment: 'right'},
            { text: 'SALES RETURN', alignment: 'right'},
            { text: 'CLOSING STOCK', alignment: 'right'}
        ]

        if(stockType === 'value') {
            header = header.concat([
                { text: 'SALE VALUE', alignment: 'right'},
                { text: 'PROFIT VALUE', alignment: 'right'}
            ])
        }

        return header;
    }

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 30, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },
                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },
                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
